import styled from "styled-components";
import { BannerBg } from "../../Styled/AllImages";
import Cookies from "js-cookie";

export const ContactWrapper = styled.section`
    background-color: var(--white);
    background-image: url(${BannerBg});
    background-repeat: repeat;
    position: relative;
    & > *{
        position: relative;
        z-index: 5;
    }
    &:after{
        content: "";
        width: 100%;
        top: 0px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.99) 50%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        z-index: 1;
        height: 100%;
        left: 0px;
        right: 0px;
        z-index: 0;
    }
    .contactPattern1{
        left: -116px;
        top: 70px;
    }
    .contactPattern2{
        right: -113px;
        bottom: 60px;
    }
    .containerContact{
        padding-top:80px;
        padding-bottom: 50px;
    }
    position:relative;
    .contactText{
        padding: 0 70px 0 70px;
    }
    .contact-text{
        font-family: var(--semiBold);
        color: var(--black);
    }
    .contact-info{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        & > *{
            width: 100%;
        }
    }
        
    .followHeading{
        margin-top: 30px;
    }
    /* .btn-text{
        background: var(--gradient);
        padding: 8px 12px;
        border-radius: 8px;
        & div{
            color:var(--white);
        }
        // & svg{
        //     [fill]{
        //         fill:var(--white);
        //     }
        // }
    } */
    .contactHContainer{
        padding-bottom: 50px;
    }
    .contact-svg{
        width: 32px;
        height: 32px;
        background-color: var(--themeColor);
        border-radius: 8px;

        svg{
            margin: auto;
        }
    }
    .contact-info button,
    .cInfoIt{
        gap: 12px;
        padding: 0;
    }
    
    .contact-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        position: relative;
        padding:20px 20px;
    }
    .Social-links{
        display: flex;
        width: 100%;
        gap: 12px;
    }
    .contact-links{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        background-color: var(--themeColor);
        border-radius: 8px;
    }
        
    `
export const ContactFormWrapper = styled.div`
    .field-box{
        display: flex;
        flex-direction: column;
        gap:2px;
        margin:0px 0 15px 0;
    }
    .input-field,
    .file-attached{
        padding: 10px 15px;
        border-radius: 10px;
        border: none;
        outline: none;
        color: var(--black);
        border: 1px solid var(--grey);
        font-family: ${() => Cookies.get("lang") === "ar" ? "var(--arLight)" : "var(--regular)"};
        font-size: ${() => Cookies.get("lang") === "ar" ? "22px" : "16px"};
    }

    .input-field::placeholder{
        color:var(--textPalceholder);
    }
    .error-message{
        font-family: var(--regular);
        /* border: 1px solid #ff00004f; */
        color: #d40404 !important;
        /* padding: 6px 8px; */
        font-size: 14px;
        margin-bottom: 0;
        /* background: #ff00004f; */
        /* -webkit-text-fill-color: white; */
    }
    .loading-btn{
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 20%;
        z-index: 10;
        background: #0b0b0b6b;
        text-align: center;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color:var(--white);
        font-family:var(--regular);

    }
    .contact-social-links{
        position: relative;
        left: 15px;
    }
    .contactBtn{
        display: flex;
        background-color: var(--themeColor);
        padding: 11px 30px;
        border-radius: 10px;
        color: var(--white);
        font-size: 17px;
        font-family: var(--bold);
        transition: all .4s ease-in-out;
        border: none;
        &:hover{
            background-color: var(--hoverBg);
            border: none;
        }
    }

`