import { LazyImage } from "../Styled/Elements"
import { Choice1, Choice2, Choice3, Choice4, Choice5, FeaturesImg1, FeaturesImg1En, FeaturesImg2, FeaturesImg2En, FeaturesImg3, FeaturesImg3En, Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6, Gallery7, Gallery8, Partner1, Partner2, Partner3, Partner4, Partner5, Partner6, Player1, Player2, TrainerImage1, TrainerImage2, TrainerImage3, TrainerImage4, TrainerImage5, TrainerImage6 } from "../Styled/AllImages"
import { Image, Shimmer } from "react-shimmer"


export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
        link: false,
    },
    {
        to: 'about',
        label: 'About',
        link: false,
    },
    {
        to: 'features',
        label: 'Features',
        link: false,
    },
    {
        to: 'howitworks',
        label: 'HowItWorks',
        link: false,
    },
    {
        to: 'faqs',
        label: 'FAQs',
        link: false,
    },
    {
        to: 'contactus',
        label: 'ContactUs',
        link: false,
    },
]
export const PageNavConfig = [
	{
        to: '/',
        label: 'Home',
        link: false,
    },
    {
        to: '/',
        label: 'About',
        link: false,
    },
    {
        to: '/',
        label: 'Features',
        link: false,
    },
    {
        to: '/',
        label: 'HowItWorks',
        link: false,
    },
    {
        to: '/',
        label: 'FAQs',
        link: false,
    },
    {
        to: '/',
        label: 'ContactUs',
        link: false,
    },
];
export const FeaturesList = [
    {
        heading: 'DiscoverHistoricalPlaces',
        text: "Explorerichhistory",
        image: {
            en: <LazyImage src={FeaturesImg1En} />, // English image
            ar: <LazyImage src={FeaturesImg1} />    // Arabic image
        },
        
    },
    {
        heading: 'EngageMultimediaContent',
        text: "BringMadinahlife",
        image: {
            en: <LazyImage src={FeaturesImg2En} />,
            ar: <LazyImage src={FeaturesImg2} />
        },
    },
    {
        heading: 'NavigatewithEase',
        text: "Gettingdestinationhas",
        image: {
            en: <LazyImage src={FeaturesImg3En} />,
            ar: <LazyImage src={FeaturesImg3} />
        },
    },

]
export const Process = [
    {
        count: '1',
        title: 'Downloadinstallapp',
        processText: "Startdownloading",
    },
    {
        count: '2',
        title: 'Chooseyourlanguage',
        processText: "Selectyourpreferred",
    },
    {
        count: '3',
        title: 'Pickplace',
        processText: "Browselisthistorical",
    },
    {
        count: '4',
        title: 'Learnanddiscover',
        processText: "Getdetailedinformation",
    },
    {
        count: '5',
        title: 'Explorethemap',
        processText: "Usetheinteractive",
    },

]
export const FaqsList = [
    {
        question: 'question1',
        answer: "answer1",
    },
    {
        question: 'question2',
        answer: "answer2",
    },
    {
        question: 'question3',
        answer: "answer3",
    },
    {
        question: 'question4',
        answer: "answer4",
    },
    {
        question: 'question5',
        answer: "answer5",
    },

]
/* 
export const trainerList = [
    {
        heading: 'Cody_Fisher',
        label: 'Football_Coach',
        text: 'text_1',
        image: <LazyImage src={TrainerImage1} />,
    },
    {
        heading: 'Esther_Howard',
        label: 'Football_Coach',
        text: 'text_1',
        image: <LazyImage src={TrainerImage2} />,
    },
    {
        heading: 'Ronald_Richards',
        label: 'Goalkeeper_Coach',
        text: 'text_1',
        image: <LazyImage src={TrainerImage3} />,
    },
    {
        heading: 'Cody_Fisher',
        label: 'Football_Coach',
        text: 'text_1',
        image: <LazyImage src={TrainerImage4} />,
    },
    {
        heading: 'Esther_Howard',
        label: 'Football_Coach',
        text: 'text_1',
        image: <LazyImage src={TrainerImage5} />,
    },
    {
        heading: 'Ronald_Richards',
        label: 'Goalkeeper_Coach',
        text: 'text_1',
        image: <LazyImage src={TrainerImage6} />,
    },

]


export const choiceData = [
    {
        image: <LazyImage src={Choice1} />,
        className: 'choice-1',
        heading: 'Community_Focus',
        text: "choice_text_1",
        delay: "0.3",
        sequenceIndex: '0',
    },
    {
        image: <LazyImage src={Choice2} />,
        className: 'choice-2',
        heading: 'Expert_Trainers',
        text: "choice_text_2",
        delay: "1.5",
        sequenceIndex: '4',

    },
    {
        image: <LazyImage src={Choice3} />,
        className: 'choice-3',
        heading: 'Proven_Track_Record',
        text: "choice_text_3",
        delay: "0.6",
        sequenceIndex: '1',
    },
    {
        image: <LazyImage src={Choice4} />,
        className: 'choice-4',
        heading: 'Personalized_Guidance',
        text: "choice_text_4",

        delay: "1.2",
        sequenceIndex: '3',
    },
    {
        image: <LazyImage src={Choice5} />,
        className: 'choice-5',
        heading: 'Facilities',
        text: "choice_text_5",
        delay: "0.9",
        sequenceIndex: '2',
    },

]
export const starSlider = [
    {
        // img: <LazyImage src={Player1} />,
        img: <Image src={Player1} fallback={<Shimmer />} />,
        heading: 'Darrell_Steward',
        text: 'Al_Ittihad',

    },
    {
        img: <Image src={Player2} fallback={<Shimmer />} />,
        heading: 'Guy_Hawkins',
        text: 'Al_Fateh',

    },
    {
        img: <Image src={Player1} fallback={<Shimmer />} />,
        heading: 'Wade_Warren',
        text: 'Al_Ittihad',

    },
    {
        img: <Image src={Player2} fallback={<Shimmer />} />,
        heading: 'Darrell_Steward',
        text: 'Al_Fateh',

    },
    {
        img: <Image src={Player1} fallback={<Shimmer />} />,
        heading: 'Guy_Hawkins',
        text: 'Al_Ittihad',

    },


]
export const photos = [
    {
        src: Gallery1,
        className: 'item-1'
    },
    {
        src: Gallery2,
        className: 'item-2'
    },
    {
        src: Gallery3,
        className: 'item-3'
    },
    {
        src: Gallery4,
        className: 'item-4'
    },
    {
        src: Gallery5,
        className: 'item-5'
    },
    {
        src: Gallery6,
        className: 'item-6'
    },
    {
        src: Gallery7,
        className: 'item-7'
    },
    {
        src: Gallery8,
        className: 'item-8'
    },
]
export const partners = [
    {
        src: Partner1,
    },
    {
        src: Partner2,
    },
    {
        src: Partner3,
    },
    {
        src: Partner4,
    },
    {
        src: Partner5,
    },
    {
        src: Partner6,
    },
    {
        src: Partner1,
    },
    {
        src: Partner2,
    },
    {
        src: Partner3,
    },
    {
        src: Partner4,
    },
    {
        src: Partner5,
    },
    {
        src: Partner6,
    },
    {
        src: Partner1,
    },
    {
        src: Partner2,
    },
    {
        src: Partner3,
    },
    {
        src: Partner4,
    },

]
 */
