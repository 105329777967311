import React from 'react'
import { AboutWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { AboutImg, AboutImgEn, AppGallery, AppStore, Landmarks, Mosques, Pattern, PlayStore, Stores } from '../../Styled/AllImages'
import { Link } from 'react-router-dom'



const About = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <AboutWrapper id='about' className='aboutWrapper'>
            <img src={Pattern} className='themePattern aboutPattern1' />
            <img src={Pattern} className='themePattern aboutPattern2' />

            <Container>
                <Row>
                    <Col md={12} lg={6} className='aboutContentWrap'>
                        <Box className='aboutContentLayers'>
                            <Box className='aboutHeadings'>
                                <HeadingStyled className='aboutMainHeading main-heading' color='var(--themeColor)'>{t('ExploreHistory')}</HeadingStyled>
                                <TextStyled className='aboutText mt-3'>
                                    {t('aboutText1')}
                                    <br />
                                    <br />
                                    {t('aboutText2')}
                                </TextStyled>
                                <TextStyled className='aboutHglText pt-3 mb-0' color="var(--themeColor)">
                                    {t('aboutText3')}
                                </TextStyled>
                            </Box>
                            <Box className='appPlatforms mt-4'>
                                <Link to="https://apps.apple.com/us/app/athar-almadinah-almonawarah/id6642691357" target='_blank'>
                                    <AppStore />
                                </Link>
                                <Link to="https://play.google.com/store/apps/details?id=com.atharalmadinah.app" target='_blank'>
                                    <PlayStore />
                                </Link>
                                <Link to="https://appgallery.huawei.com/app/C112361829" target='_blank'>
                                    <AppGallery />
                                </Link>
                            </Box>
                        </Box>
                    </Col>
                    <Col md={12} lg={6} className='aboutImgWrapper'>
                        <Box className='aboutImg'>
                        {i18n.language === 'ar' ? (
                            <LazyImage src={AboutImg} />
                        ) : (
                            <LazyImage src={AboutImgEn} />
                        )}
                            
                        </Box>
                    </Col>
                </Row>
            </Container>
        </AboutWrapper>
    )
}

export default About