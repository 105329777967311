import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, HeadingStyled2, HeadingStyledB, HeadingStyledC, HeadingStyledD, SpanStyled, SpanStyled2, SubHeadingStyled } from '../../components/Styled/Elements'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import Header from '../header/Header'
import styled from 'styled-components'
import PagesHeader from '../header/PagesHeader'
import PagesFooter from '../footer/PagesFooter'


const PrivacyPolicy = () => {
	const { t } = useTranslation()

	return (
		<>
			<PagesHeader />
			<ContainerPrivacyTerm className="container common-container privacy-terms-container">
				<Box>
					<HeadingStyled2 className='prTcTitle'>
						{t("title")}
					</HeadingStyled2>
				</Box>
				<Box className="mt-3 privacyContainer">
					<SpanStyled2>
						{t("intro")}
					</SpanStyled2>
					<SpanStyled2>
						{t("info")}
					</SpanStyled2>
					<SpanStyled2>
						{t("agreement")}
					</SpanStyled2>
					<SpanStyled2>
						{t("termsDefinition")}
					</SpanStyled2>
					<Box>
						<HeadingStyledD className='privacySubH'>
							{t("informationCollectionTitle")}
						</HeadingStyledD>
					</Box>
					
					<SpanStyled2>
						{t("informationCollectionDescription")}
					</SpanStyled2>
					<SpanStyled2>
						{t("informationCollectionThirdParty")}
					</SpanStyled2>
					<Box>
						<HeadingStyledD className='privacySubH'>
							{t("logDataTitle")}
						</HeadingStyledD>
					</Box>
					
					<SpanStyled2>
						{t("logDataDescription")}
					</SpanStyled2>
					<Box>
						<HeadingStyledD className='privacySubH'>
							{t("cookiesTitle")}
						</HeadingStyledD>
					</Box>
					
					<SpanStyled2>
						{t("cookiesDescription")}
					</SpanStyled2>
					<SpanStyled2>
						{t("cookiesDisclaimer")}
					</SpanStyled2>
					<Box>
						<HeadingStyledD className='privacySubH'>
							{t("serviceProvidersTitle")}
						</HeadingStyledD>
					</Box>
					
					<SpanStyled2>
						{t("serviceProvidersReasons")}
					</SpanStyled2>
					<Box>
						<ul className='prTcList'>
							<li>{t("serviceProvidersReasons1")}</li>
							<li>{t("serviceProvidersReasons2")}</li>
							<li>{t("serviceProvidersReasons3")}</li>
							<li>{t("serviceProvidersReasons4")}</li>
						</ul>
					</Box>
					<SpanStyled2>
						{t("serviceProvidersDisclosure")}
					</SpanStyled2>
					<Box>
						<HeadingStyledD className='privacySubH'>
							{t("securityTitle")}
						</HeadingStyledD>
					</Box>
					
					<SpanStyled2>
						{t("securityDescription")}
					</SpanStyled2>
					<Box>
						<HeadingStyledD>
							{t("linksTitle")}
						</HeadingStyledD>
					</Box>
					
					<SpanStyled2>
						{t("linksDescription")}
					</SpanStyled2>
					<Box>
						<HeadingStyledD className='privacySubH'>
							{t("childrenPrivacyTitle")}
						</HeadingStyledD>
					</Box>
					
					<SpanStyled2>
						{t("childrenPrivacyDescription")}
					</SpanStyled2>
					<Box>
						<HeadingStyledD className='privacySubH'>
							{t("changesPolicyTitle")}
						</HeadingStyledD>
					</Box>
					
					<SpanStyled2>
						{t("changesPolicyDescription")}
					</SpanStyled2>
					<Box>
						<HeadingStyledD className='privacySubH'>
							{t("contactUsTitle")}
						</HeadingStyledD>
					</Box>
					
					<SpanStyled2>
						{t("contactUsDescription")}
						<span className='engWd'>info@atharalmadinah.com.</span>
					</SpanStyled2>

				</Box>
			</ContainerPrivacyTerm>
			<PagesFooter />
		</>
	)
}

export default PrivacyPolicy

export const ContainerPrivacyTerm = styled.div`
	padding-top: 120px;
	.privacyContainer{
		& > span{
			display: inline-block;
			width: 100%;
			margin-bottom: 20px;
		}
	}
`