import React, { useEffect } from 'react'
import { FooterWapper } from './Styled'
import { Container, Row } from 'react-bootstrap'
import { Box, SpanStyled } from '../Styled/Elements'
import { FoooterLogo, FooterInstagram, FooterLinkedin, FooterSnapchat, FooterTikTok, FooterTwitter } from '../Styled/AllImages'
import { PageNavConfig } from '../data/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { useNavigate, Navigate } from 'react-router-dom'

const PagesFooter = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    const date_year = new Date();
    const toMainPage = (label, isLink, to) => {
		// handleToggle();
		if (isLink) {
			navigate(to);
		} else navigate(`/?section=${label}`);
	};
	// const handleToggle = () => {
	// 	setToggle(!toggle);
	// 	const element = document.querySelector(".nav-bar-list");
	// 	element.classList.toggle("showToggle");
	// };

	// useEffect(() => {
	// 	const handleScroll = () => {
	// 		if (window.scrollY > 1) {
	// 			setIsSticky(true);
	// 		} else {
	// 			setIsSticky(false);
	// 		}
	// 	};
	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll);
	// 	};
	// }, []);
    return (
        <FooterWapper className='container-fluid border-top-line'>
            <Container>
                <Row>
                    <Box className='d-flex align-items-center gap-2 footer-content justify-space-between footerContentWrapper'>
                        <Box className='footer-logo'>
                            <FoooterLogo />
                        </Box>
                        <Box className='d-flex flex-column justify-content-center align-items-center footerListContainer'>
                            <Box className='footer-list'>
                                {PageNavConfig.map((item, key) => (
                                    <Box key={key}>
                                        <a className=""
											onClick={() => toMainPage(item.label, item.link, item.to)}
										>
											{t(item.label)}
                                        </a>
                                    </Box>
                                ))}
                                {/* {PageNavConfig.map((item, key) => (
									<Box key={key}>
										<a className=""
											onClick={() => toMainPage(item.label, item.link, item.to)}
										>
											{t(item.label)}
										</a>
									</Box>
								))} */}
                                <Link className='prTcLink' onClick={() => navigate('/privacy')}>{t('title')}</Link>
                            </Box>
                            <Box className='footer-revised'>
                                <SpanStyled className='text-center' color='var(--white)' transform='capitalize'>{t('AllRightReserved2')} <span className='dgts'> &copy; {date_year.getFullYear()}</span> {t('MadinaMunawwara')}  </SpanStyled>
                                {/* <SpanStyled className='text-center' color='var(--white)' transform='capitalize'>{t('Developed_by')}<SpanStyled className='fowl' color='var(--themeColor)' onClick={FowlWeb}>{t('Fowl')}</SpanStyled></SpanStyled> */}
                            </Box>
                        </Box>
                        
                    </Box>
                </Row>
            </Container>
        </FooterWapper>
    )
}

export default PagesFooter