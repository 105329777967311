import React from 'react'
import { FaqsWrapper, FeaturesWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { AboutImg, AppGallery, AppStore, Landmarks, Mosques, PlayStore, Stores } from '../../Styled/AllImages'
import { Link } from 'react-router-dom'
import QuestionCard from './Questions'



const Faqs = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <FaqsWrapper id='faqs' className='faqsWrapper'>
            <Container fluid className='faqsBg'>
                <Container>
                    <Row>
                        <Col md={12} className='faqsContentWrap'>
                            <Box className='faqsHeadings'>
                                <HeadingStyled className='faqsMainHeading main-heading text-center justify-content-center goldGradientTxt' color='var(--themeColor)'>{t('FAQs')}</HeadingStyled>
                                
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className='faqsQAWrap'>
                            <QuestionCard />
                        </Col>
                    </Row>
                </Container>
            </Container>
            
                
        </FaqsWrapper>
    )
}

export default Faqs