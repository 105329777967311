import styled from "styled-components";
import Cookies from 'js-cookie';

export const FooterWapper = styled.footer`
    background-color: var(--black);
    .footer-list{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;
        & a{
            font-family: ${() => Cookies.get("lang") === "ar" ? "var(--arLight)" : "var(--semiBold)"};
            font-size: ${() => Cookies.get("lang") === "ar" ? "32px" : "17px"};
            color: var(--white);
            white-space: nowrap;
            &:hover{
                color: var(--white);
                text-decoration: underline;
                // background: var(--darkGradient);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
            }
        }
    }
    .dgts{
        font-family: var(--popMedium);
        font-size: 13px;
    }
    .footer-logo{
        margin-left: 0;
        margin-right: auto;
    }
    .footerListContainer{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        gap: 10px;
    }

    .fowl{
        cursor: pointer;
        padding-left: 5px;
        padding-right: 5px;
        &:hover{
            text-decoration: underline;
        }
    }
`