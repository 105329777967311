import React from 'react'
import { FeaturesCardWrapper, FeaturesWrapper, HowItWorksProcessWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, HeadingStyledC, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { AboutImg, AppGallery, AppStore, Landmarks, Mosques, PlayStore, Stores } from '../../Styled/AllImages'
import { Link } from 'react-router-dom'
import { Process } from '../../data/Data'



const ProcessWorks = () => {
    const { t, i18n } = useTranslation();
    

    return (
        <HowItWorksProcessWrapper className='howItWorksContentLayers'>
            {Process.map((item, key) => (
                <Box className="howitWorksPoint" key={key}>
                            <Box className="howitWorksPointCount">
                            {t(item.count)}
                            </Box>
                            <Box className="howitWorksPointTitleTxt">
                                <HeadingStyledC className='howitWorksPointTitle main-heading'>
                                    {t(item.title)}
                                </HeadingStyledC>
                                <TextStyled className='howitWorksPointTxt mt-0'>
                                    {t(item.processText)}
                                </TextStyled>
                            </Box>
                        </Box>
                
            ))}
        </HowItWorksProcessWrapper>
    )
}

export default ProcessWorks