import styled from "styled-components";
import { BannerBg, Faqs, FeaturesBg } from "../../Styled/AllImages";
import Cookies from 'js-cookie';

export const FaqsWrapper = styled.section`
    padding: 0px 0 0px 0;
    .faqsBg{
        background-image: url(${Faqs});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top 0 center;
        padding-top: 75px;
        padding-bottom: 80px;
    }
    .faqsMainHeading{
        margin-bottom: 45px;
    }
    .accordion-item{
        margin-bottom: 12px;
        background: var(--themeGradientEn);
        border: none;
        border-radius: 12px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        box-shadow: 0px 10px 90px 0px rgba(0, 0, 0, .2);
        .accordion-button{
            background-color: transparent;
            box-shadow: none;
            background: var(--goldGradient);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            font-family: ${() => Cookies.get("lang") === "ar" ? "var(--arRegular)" : "var(--bold)"};
            font-size: ${() => Cookies.get("lang") === "ar" ? "40px" : "20px"};
            line-height: ${() => Cookies.get("lang") === "ar" ? "36px" : "26px"};
            padding-top: ${() => Cookies.get("lang") === "ar" ? "14px" : "14px"};
            padding-bottom: ${() => Cookies.get("lang") === "ar" ? "14px" : "14px"};
            &:after{
                background-image: var(--accord-btn-icon);
            }
        }
        .accordion-body{
            padding-top: 0;
        }
    }


`
export const FaqsCardWrapper = styled.div`
    margin-top: -115px;
    .featuresColContent{
        background-color: var(--white);
        background-image: url(${BannerBg});
        background-repeat: repeat;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0px 20px 50px -10px rgba(0, 0, 0, .2);
        height: 100%;
        position: relative;
        padding: 40px 25px 320px 25px;
        & > *{
            position: relative;
            z-index: 5;
        }
        &:after{
            content: "";
            width: 100%;
            bottom: 0px;
            background: linear-gradient(20deg, rgba(255, 255, 255, 0.99) 50%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            z-index: 1;
            height: 410px;
            left: 0px;
            right: 0px;
            z-index: 0;
        }
        span{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 5;
        }
    }
    
    
`

