import styled from "styled-components";
import { BannerBg, FeaturesBg } from "../../Styled/AllImages";

export const FeaturesWrapper = styled.section`
    padding: 0px 0 0px 0;
    .featuresBg{
        background-image: url(${FeaturesBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top 0 center;
        padding-top: 75px;
        padding-bottom: 170px;
    }
    .featuresContentWrap{
        padding: 0 70px 0 70px;
    }


`
export const FeaturesCardWrapper = styled.div`
    margin-top: -115px;
    .featuresColContent{
        background-color: var(--white);
        background-image: url(${BannerBg});
        background-repeat: repeat;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0px 20px 50px -10px rgba(0, 0, 0, .2);
        height: 100%;
        position: relative;
        padding: 40px 25px 320px 25px;
        & > *{
            position: relative;
            z-index: 5;
        }
        &:after{
            content: "";
            width: 100%;
            bottom: 0px;
            background: linear-gradient(20deg, rgba(255, 255, 255, 0.99) 50%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            z-index: 1;
            height: 410px;
            left: 0px;
            right: 0px;
            z-index: 0;
        }
        span{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 5;
        }
    }
    
    
`

