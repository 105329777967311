import i18n from './Languages/INEXT';
import React, { useEffect } from 'react'
import Header from './components/header/Header';
import { Box } from './components/Styled/Elements';
import { privateRoute } from './route/PrivateRoute';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { scroller, animateScroll as scroll, Events } from "react-scroll";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: 'smooth' })
  }, [location]);
  const [cookies, setCookie] = useCookies(['lang']);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCookie('lang', lng, { path: '/' }); // Set cookie with the language preference
  };

  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const removeQueryParam = () => {
    let section = searchParams.get("section");
    if (section) {
      section = section.toLowerCase();
      const element = document.getElementById(section);
      if (element) {
        scroller.scrollTo(
          section,
          {
            duration: 500,
            smooth: true,
          });
      }
    }
  };
  useEffect(() => {
    removeQueryParam();
  }, [location]);
  useEffect(() => {
    Events.scrollEvent.register('begin', (to, element) => {
      if (searchParams.has("section")) {
        searchParams.delete("section");
        const newUrl = location.pathname + searchParams.toString();
        navigate(newUrl);
      }
    });

    return () => {
      Events.scrollEvent.remove('begin');
    };
  });

  return (
    <>
      <Box className='main-artical'>
        {/* <Header /> */}
        <Routes>
          {privateRoute.map((list, key) => (
            <Route to={key} key={key} {...list} />
          ))}
        </Routes>
        {/* <Footer /> */}
      </Box>
    </>
  );
}

export default App;
