import React from 'react'
import { FeaturesCardWrapper, FeaturesWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, HeadingStyledB, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { AboutImg, AppGallery, AppStore, Landmarks, Mosques, PlayStore, Stores } from '../../Styled/AllImages'
import { Link } from 'react-router-dom'
import { FeaturesList } from '../../data/Data'



const FeaturesCard = () => {
    const { t, i18n } = useTranslation();
    

    return (
        <FeaturesCardWrapper id='features' className='featuresCardWrapper'>
            <Container>
                <Row className='featuresCardRow'>
                    {FeaturesList.map((item, key) => (
                        <Col sm={12} md={6} lg={4} className='featuresColWrap' key={key}>
                            <Box className='featuresColContent'>
                                <HeadingStyledB className='featuresTitleHeading main-heading' >
                                    {t(item.heading)}
                                </HeadingStyledB>
                                <TextStyled className='featureCardText mt-2'>
                                    {t(item.text)}
                                </TextStyled>
                                {i18n.language === 'ar' ? item.image.ar : item.image.en}
                            </Box>
                        </Col>
                    ))}
                    
                </Row>
            </Container>
        </FeaturesCardWrapper>
    )
}

export default FeaturesCard