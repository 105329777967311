import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./app/assets/css/style.scss";
import "./app/assets/css/responsive.scss";
// import "video-react/dist/video-react.css";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n, { i18nInitialized } from "./app/Languages/INEXT";
import App from "./app/App";

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Router>
          <App />
        </Router>
      </I18nextProvider>
    </React.StrictMode>
  );

  reportWebVitals();
};

i18nInitialized.then(renderApp);
