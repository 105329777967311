import React from 'react'
import { FaqsCardWrapper, FeaturesCardWrapper, FeaturesWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { AboutImg, AppGallery, AppStore, Landmarks, Mosques, PlayStore, Stores } from '../../Styled/AllImages'
import { Link } from 'react-router-dom'
import { FaqsList, FeaturesList } from '../../data/Data'
import Accordion from 'react-bootstrap/Accordion';


const QuestionCard = () => {
    const { t, i18n } = useTranslation();
    

    return (
        <Accordion defaultActiveKey="0" flush>
            {FaqsList.map((item, key) => (
                <Accordion.Item eventKey={key.toString()} key={key}>
                    <Accordion.Header>{t(item.question)}</Accordion.Header>
                    <Accordion.Body>
                        <TextStyled className='answerText mb-0' color="var(--white)">
                            {t(item.answer)}
                        </TextStyled>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
        
    )
}

export default QuestionCard