import React from 'react'
import { HowItWorksWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { HowItWorksImg, HowItWorksImgEn } from '../../Styled/AllImages'
import ProcessWorks from './Process'




const HowItWorks = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <HowItWorksWrapper id='howitworks' className='howItWorksWrapper'>
            <Container>
                <Row>
                    <Col md={12} className='howItWorksContentWrap'>
                        <HeadingStyled className='howItWorksMainHeading main-heading text-center justify-content-center' color='var(--themeColor)'>
                            {t('Howtheappworks')}
                        </HeadingStyled>
                    </Col>
                    <Col md={12} lg={6} className='howItWorksContentWrap'>
                        <ProcessWorks />
                    </Col>
                    <Col md={12} lg={6} className='howItWorksImgWrapper'>
                        <Box className='howItWorksImg text-center justify-content-center'>
                        {i18n.language === 'ar' ? (
                            <LazyImage src={HowItWorksImg} />
                        ) : (
                            <LazyImage src={HowItWorksImgEn} />
                        )}
                            
                        </Box>
                    </Col>
                </Row>
            </Container>
        </HowItWorksWrapper>
    )
}













export default HowItWorks