import Index from "./Index";
import ErrorPage from "../components/Page404/ErrorPage";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";

export const privateRoute = [
    {
        path: "/",
        element: <Index />,
    },
    {
        path: "/privacy",
        element: <PrivacyPolicy />,
    },
    {
        path: "/*",
        element: <ErrorPage />,
    },
]
