import React from 'react'
import { FeaturesWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { AboutImg, AppGallery, AppStore, Landmarks, Mosques, PlayStore, Stores } from '../../Styled/AllImages'
import { Link } from 'react-router-dom'
import FeaturesCard from './Card'



const Feature = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <FeaturesWrapper id='features' className='featuresWrapper'>
            <Container fluid className='featuresBg'>
                <Container>
                    <Row>
                        <Col md={12} className='featuresContentWrap'>
                            <Box className='featuresHeadings'>
                                <HeadingStyled className='featuresMainHeading main-heading text-center justify-content-center goldGradientTxt' color='var(--themeColor)'>{t('OurFeatures')}</HeadingStyled>
                                <TextStyled className='FeaturesText mt-2 text-center justify-content-center' color="var(--white)">
                                    {t('Discoverunique')}
                                </TextStyled>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <FeaturesCard />
                
        </FeaturesWrapper>
    )
}

export default Feature